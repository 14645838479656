<div class="modal fade" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="attachmentsTitle">
    <div class="modal-dialog" role="document">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
            <div class="modal-header">
                <h2 class="modal-title" id="attachmentsTitle">
                    {{'attachments' | i18n}}
                    <small *ngIf="cipher">{{cipher.name}}</small>
                </h2>
                <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <table class="table table-hover table-list" *ngIf="cipher && cipher.hasAttachments">
                    <tbody>
                        <tr *ngFor="let a of cipher.attachments">
                            <td class="table-list-icon">
                                <i class="fa fa-fw fa-lg fa-file-o" *ngIf="!a.downloading" aria-hidden="true"></i>
                                <i class="fa fa-spinner fa-lg fa-fw fa-spin" *ngIf="a.downloading"
                                    aria-hidden="true"></i>
                            </td>
                            <td class="wrap">
                                <div class="d-flex">
                                    <a href="#" appStopClick (click)="download(a)">{{a.fileName}}</a>
                                    <div *ngIf="showFixOldAttachments(a)" class="ml-2">
                                        <a href="https://help.bitwarden.com/article/attachments/#fixing-old-attachments"
                                            target="_blank" rel="noopener">
                                            <i class="fa fa-exclamation-triangle text-warning"
                                                title="{{'attachmentFixDesc' | i18n}}" aria-hidden="true"></i>
                                            <span class="sr-only">{{'attachmentFixDesc' | i18n}}</span></a>
                                        <button type="button" class="btn btn-outline-primary btn-sm m-0 py-0 px-2"
                                            (click)="reupload(a)" #reuploadBtn [appApiAction]="reuploadPromises[a.id]"
                                            [disabled]="reuploadBtn.loading">{{'fix' | i18n}}</button>
                                    </div>
                                </div>
                                <small>{{a.sizeName}}</small>
                            </td>
                            <td class="table-list-options">
                                <button class="btn btn-outline-danger" type="button" appStopClick
                                    appA11yTitle="{{'delete' | i18n}}" (click)="delete(a)" #deleteBtn
                                    [appApiAction]="deletePromises[a.id]" [disabled]="deleteBtn.loading">
                                    <i class="fa fa-trash-o fa-lg fa-fw" [hidden]="deleteBtn.loading"
                                        aria-hidden="true"></i>
                                    <i class="fa fa-spinner fa-spin fa-lg fa-fw" [hidden]="!deleteBtn.loading"
                                        title="{{'loading' | i18n}}" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h3>{{'newAttachment' | i18n}}</h3>
                <label for="file" class="sr-only">{{'file' | i18n}}</label>
                <input type="file" id="file" class="form-control-file" name="file" required>
                <small class="form-text text-muted">{{'maxFileSize' | i18n}}</small>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
                    <span>{{'save' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">{{'close'
                    | i18n}}</button>
            </div>
        </form>
    </div>
</div>
