<div class="page-header">
    <h1>{{'vault' | i18n}}</h1>
</div>
<div class="mt-4">
    <ng-container *ngIf="ciphers.length">
        <table class="table table-hover table-list table-ciphers">
            <tbody>
                <tr *ngFor="let c of ciphers">
                    <td class="table-list-icon">
                        <app-vault-icon [cipher]="c"></app-vault-icon>
                    </td>
                    <td class="reduced-lh wrap">
                        <a href="#" appStopClick (click)="selectCipher(c)" title="{{'editItem' | i18n}}">{{c.name}}</a>
                        <ng-container *ngIf="!organization && c.organizationId">
                            <i class="fa fa-share-alt" appStopProp title="{{'shared' | i18n}}" aria-hidden="true"></i>
                            <span class="sr-only">{{'shared' | i18n}}</span>
                        </ng-container>
                        <ng-container *ngIf="c.hasAttachments">
                            <i class="fa fa-paperclip" appStopProp title="{{'attachments' | i18n}}"
                                aria-hidden="true"></i>
                            <span class="sr-only">{{'attachments' | i18n}}</span>
                        </ng-container>
                        <br>
                        <small>{{c.subTitle}}</small>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</div>
<ng-template #cipherAddEdit></ng-template>
